<template>
    <BT-Blade-Items
        addBladeName="order-sheet"
        bladeName="order-sheets"
        :bladesData="bladesData"
        canAdd
        canDelete
        canSearchLocal
        :headers="[
            { text: 'Sheet Name', value: 'orderSheetName', display: true, title: 1, searchable: true }]"
        :loadingMsg="loadingMsg"
        navigation="order-sheets"
        title="Order Sheets"
        :actualUsedHeight="175"
        useServerPagination
        :defaultBladeWidth="300">
        <template v-slot:orderSheetName="{ item }">
            {{ item.orderSheetName }}
        </template>
        <template v-slot:itemActions="{ item }">
            <v-edit-dialog large @save="saveItem(item)">
                <v-btn small icon>
                    <v-icon small>mdi-pencil</v-icon>
                </v-btn>
                <template v-slot:input>
                    <v-text-field
                        hide-details
                        v-model.number="item.orderSheetName" />
                </template>
            </v-edit-dialog>
        </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Order-Sheets-Blade',
    data() {
        return {
            loadingMsg: null
        }
    },
    props: {
        bladesData: null
    },
    methods: {
        async saveItem(sheetItem) {
            try {
                this.loadingMsg = 'Saving';
                var res = await this.$BlitzIt.store.patch('order-sheets', sheetItem);
                sheetItem.rowVersion = res.rowVersion;
            }
            finally {
                this.loadingMsg = null;
            }
        }
    }
}
</script>